const configurations = {
    PASSWORD: {
        key: 'password',
        value: 'Sunny@123',
        title: 'Password',
    },
    PERDAYLIMIT: {
        key: 'perdaylimit',
        value: 200,
        title: 'Per Day Limit',
    },
    Is_SVS_Form_On: {
        key: 'Is_SVS_Form_On',
        value: 200,
        title: 'Per Day Limit',
    },
    LIVE_URL1: {
        key: 'LIVE_URL1',
        value: 'https:test.rscyou.in/api',
        title: 'LIVE_URL_Base_With_API',
    },
    LIVE_URL2: {
        key: 'LIVE_URL2',
        value: 'https:test.rscyou.in',
        title: 'LIVE_URL_Base_WithOut_API',
    },
    Dev_URL1: {
        key: 'Dev_URL1',
        value: 'https:testdev.rscyou.in/api',
        title: 'Dev_URL_Base_With_API',
    },
    Dev_URL2: {
        key: 'Dev_URL1',
        value: 'https:testdev.rscyou.in',
        title: 'Dev_URL_Base_WithOut_API',
    },
    Dev_Local_URL1: {
        key: 'Dev_Local_URL1',
        value: 'https://localhost:7095/api',
        title: 'Dev_Local_URL_Base_With_API',
    },
    Dev_Local_URL2: {
        key: 'URL',
        value: 'https://localhost:7095',
        title: 'Dev_Local_URL_Base_Without_API',
    },
    IsDevMode: false,
    ControllerName: {
        SVSRegistrations: 'SVSRegistrations',
        UpdateConfig: 'UpdateConfig',

    }
};


export default configurations;