import React, { Component, useEffect, useState } from 'react';
import AppointmentsDetails from './AppointmentsDetails';
import axios from 'axios';
import configurations from '../Common/Constants';

export const Configuration = () => {

    let ConfigEmptyObject = { data: null, success: false, message: "" };
    const [isAuthenticated, SetIsAuthenticated] = useState(false);
    const [isPasswordFetched, SetIsPasswordFetched] = useState(false);
    const [passwordFetched, SetPasswordFetched] = useState('');
    const [mode, SetMode] = useState('update');
    const [password, SetPassword] = useState('');
    const [updateTextboxConfig, SetUpdateTextboxConfig] = useState('');
    const [updateDropdownConfig, SetUpdateDropdownConfig] = useState('-1');
    const [addTextboxKeyConfig, SetAddTextboxKeyConfig] = useState('');
    const [addTextboxValueConfig, SetAddTextboxValueConfig] = useState('');
    const [configAdded, SetConfigAdded] = useState(0);
    const [configsList, SetConfigsList] = useState(ConfigEmptyObject);
    let passcodev = "";
    let prefixUrl = '';

    const GetUrlBasedOnTheMode = () => {
        return configurations.IsDevMode ? configurations.Dev_Local_URL1.value : '/api';
    }

    useEffect(() => {
        // logic for fetecing the config details
        prefixUrl = GetUrlBasedOnTheMode();
        if (isAuthenticated) {
            axios.get(`${prefixUrl}/UpdateConfig`).then((response) => {
                if (response.data.success) {
                    SetConfigsList(response.data);

                }
            });
        }

    }, [configAdded, isAuthenticated])

    useEffect(() => {
        // logic for fetecing the config details

        GetInitialAuthData();

    }, []);

    useEffect(() => {
        // logic for fetecing the config details
        GetInitialAuthData();

    }, [isAuthenticated]);

    const GetInitialAuthData = () => {
        prefixUrl = GetUrlBasedOnTheMode();   
        axios.get(`${prefixUrl}/UpdateConfig/${configurations.PASSWORD.key}`).then((response) => {
            
            if (response.data.success) {
                passcodev = response.data.data.value;
                SetIsPasswordFetched(true);
                SetPasswordFetched(passcodev);
            }

        });
    }



    const handleAuth = () => {
        if (passwordFetched === password) {
            SetIsAuthenticated(true);
        }
        else {
            alert('Passcode is wrong!!! Please try again');
            SetPassword('');
        }
    }

    const handleUpdateConfig = () => {
        prefixUrl = GetUrlBasedOnTheMode();
        if (updateDropdownConfig == '-1') {
            alert('please select config name');
            return;
        }
        debugger;
       
        var myHeaders = new Headers();
        myHeaders.append("content-type", "application/json");

        let keyText = updateDropdownConfig.split(':')[0];
        var raw = JSON.stringify({
            "Id": "0",
            "Key": keyText,
            "Value": updateTextboxConfig
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${prefixUrl}/UpdateConfig/1`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    alert('Data updated');
                    SetUpdateDropdownConfig('-1');
                    SetUpdateTextboxConfig('');

                    if (keyText === 'password') {
                        SetIsAuthenticated(false);
                    }
                }
                else {
                    alert('Data not updated');
                }
            })
            .catch(error => console.log('error', error));
    }

    const handleAddConfig = () => {

        if (addTextboxKeyConfig == '' || addTextboxValueConfig == '') {
            alert('please enter values');
            return;
        }

        prefixUrl = GetUrlBasedOnTheMode();
        console.log(prefixUrl);
        var content = { Id: 0, Key: addTextboxKeyConfig, Value: addTextboxValueConfig };
        const config = { headers: { 'Content-Type': 'application/json' } };

        axios.post(`${prefixUrl}/UpdateConfig`, content, config).then((response) => {
            if (response.data.success) {
                alert('Data Added');
                SetConfigAdded(content);
                SetAddTextboxKeyConfig('');
                SetAddTextboxValueConfig('');
            }
            else {
                alert('Data not Added');
            }
        });
    }
    return (
        <>
            {!isAuthenticated &&
                <div>
                    <h3>In this section you can add update common configs</h3>
                    <br />
                    <div>
                        Enter Passcode to continue <input type="password" className="form-control" value={password} onChange={(e) => SetPassword(e.target.value)} />
                        {isPasswordFetched && <button className="btn btn-success" onClick={handleAuth}>Authenticate</button>}

                    </div>
                </div>
            }
            {isAuthenticated &&

                <>
                    <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={`nav-link ponter ${mode === 'add' ? 'active active_border_add' : ''}`} aria-current="page" onClick={() => SetMode('add')} >Add</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ponter ${mode === 'update' ? 'active active_border_update' : ''}`} onClick={() => SetMode('update')}>Update</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ponter ${mode === 'svs_Appoinment' ? 'active active_border_svs_Appoinment' : ''}`} onClick={() => SetMode('svs_Appoinment')}>SVS Appointments</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link ponter text-danger" onClick={() => SetIsAuthenticated(false)}>Logout</a>
                            </li>
                        </ul>
                    </div>
                    {mode === "update" ?

                        <div>
                            <br />
                            <h4>Update common configs</h4>

                            <select className='form-control mt-2' value={updateDropdownConfig} onChange={(e) => SetUpdateDropdownConfig(e.target.value)}>
                                { configsList.success &&
                                    //?
                                    //<>
                                    //    <option value="-1">Select Config</option>
                                    //    <option value="PerDayLimit">Per Day Limit</option>
                                    //</>
                                    //:
                                    <>
                                        <option value="-1">Select Config</option>
                                        {configsList.data.map((c, i) => <option value={c.key + ":" + c.value} key={`config-${i}`}>{c.key}</option>)}


                                    </>
                                }
                            </select>
                        Enter New value <input type="text" className="form-control" value={updateTextboxConfig} onChange={(e) => SetUpdateTextboxConfig(e.target.value)} />
                        <button className="btn btn-success" onClick={handleUpdateConfig}>Update</button>
                            
                        </div>
                        :
                        ''
                    }
                    {mode === "add" ?

                        <div>
                            <br />
                            <h4>Add common configs</h4>
                            Enter New key <input type="text" className="form-control" value={addTextboxKeyConfig} onChange={(e) => SetAddTextboxKeyConfig(e.target.value)} />
                            Enter key's value <input type="text" className="form-control" value={addTextboxValueConfig} onChange={(e) => SetAddTextboxValueConfig(e.target.value)} />
                            <button className="btn btn-success" onClick={handleAddConfig}>Add</button>
                        </div>
                        :
                        ''
                    }

                    {mode === "svs_Appoinment" ?

                        <div>
                            <AppointmentsDetails />
                        </div>
                        :
                        ''
                    }
                </>
            }

        </>);
}


//export class Counter extends Component {
//    static displayName = Counter.name;

//    constructor(props) {
//        super(props);
//        this.state = { currentCount: 0 };
//        this.incrementCounter = this.incrementCounter.bind(this);
//    }

//    incrementCounter() {
//        this.setState({
//            currentCount: this.state.currentCount + 1
//        });
//    }

//    render() {
//        return (

//            <div>
//                <button className='btn btn-info' onClick={() => alert('hello shyam')}>Click me!</button>
//                <h1>Counter</h1>

//                <p>This is a simple example of a React component.</p>

//                <p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>

//                <button className="btn btn-primary" onClick={this.incrementCounter}>Increment</button>
//            </div>
//        );
//    }
//}
