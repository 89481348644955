import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import configurations from '../Common/Constants';
export const Home = () => {
    let RegisterEmptyObject = { data: null, success: false, message: "" };
    const [appointmentData, setAppointmentData] = useState(RegisterEmptyObject);
    let prefixUrl = '';
    const GetUrlBasedOnTheMode = () => {
        return configurations.IsDevMode ? configurations.Dev_Local_URL2.value : '';
    }

    useEffect(() => {
        prefixUrl = GetUrlBasedOnTheMode();
        axios.get(`${prefixUrl}/SVSRegistrations`).then((response) => {
            setAppointmentData(response.data);
        });
    }, []);

    return (
        <>
            <h1>Hello, Farmers!</h1>
            <h4>Welcome Farmer Registration Form SVS</h4>
            <h5>Total Registration under village seed scheme are
                {appointmentData.success == false ? (<h2>Loading.....</h2>) : 
                    (<span className="text-success badge"><b><h5> {appointmentData.data.length}</h5></b></span>)}
                </h5>
            <br />
            <Link className='btn btn-info btn-lg text-white' to= '/scscp-registration' >Click here for start your registration</Link>
        </>);
}
